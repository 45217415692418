import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileAlt, faDownload } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
const DownloadReport = props => {
  return (
    <a
      className="d-xs-flex mt-10 mb-10 mr-20 mr-xs-0 _a_download"
      href={props.data.document_file_path}
      title={"Opens in a new window"}
      target="_blank"
    >
      <div className="d-xs-flex download-btn background-color-secondary-1 pl-20 pr-20 pb-20 pt-20">
        <div className="mr-25 mr-xs-20">
          <FontAwesomeIcon
            className="color-primary-4 text-size-h1"
            icon={faFileAlt}
          />
        </div>
        <div className="text-size-medium font-secondary-regular color-primary-1">
          <div className="font-secondary-bold _a_download_name">{props.data.document_name}</div>
          <div>
            <FontAwesomeIcon className="color-primary-2" icon={faDownload} />
          </div>
        </div>
      </div>
    </a>
  )
}

export default DownloadReport
