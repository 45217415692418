import { getNestedObjectValue } from "../global"

export const brightCoveUrl = async ({ brightCoveVideoRefId }) => {
  const brightCovePk = process.env.BRIGHTCOVE_PK
  const brightcoveAccountId = process.env.BRIGHTCOVE_ACCOUNTID
  const requestOptions = {
    method: "GET",
    headers: { Accept: `application/json;pk=${brightCovePk}` },
  }

  let response = await fetch(
    `${process.env.BRIGHTCOVE_APIURL}/${brightcoveAccountId}/videos?q=reference_id:${brightCoveVideoRefId}`,
    requestOptions
  )
  response = await response.json()

  let videoUrl = null
  if (response && response.count === 1) {
    const videoSources = getNestedObjectValue(response, "videos.0.sources")
    if (videoSources && videoSources.length) {
      let maxVideoWidth = 0

      videoSources.forEach(function (rendition) {
        if (
          rendition.width >= maxVideoWidth &&
          rendition.src &&
          rendition.src.indexOf("https://") != -1
        ) {
          maxVideoWidth = rendition.width
          videoUrl = rendition.src
        }
      })
    }

    return videoUrl
  }
}
