import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class PlayListThumbanilSlider extends React.Component {
  constructor(props) {
    super(props);
    this.updateVideoPayerTime = this.updateVideoPayerTime.bind(this);
  }

  componentDidMount() {
    this.goToSlider();
  }

  componentDidUpdate() {
    this.goToSlider();
  }

  goToSlider() {
    const sliderGoTo = this.props.playList.findIndex((playlist) => playlist._id === this.props.activeThumbanilSlideID);
    if (sliderGoTo !== -1) {
      this.slider.slickGoTo(parseInt(sliderGoTo));
    }
  }

  updateVideoPayerTime(event) {
    const startTime = event.currentTarget.getAttribute('data-streamtime');
    const presentationId = event.currentTarget.getAttribute('data-presentationid');

    if (window.jwplayer) {
      window.jwplayer(`ondemand-${presentationId}`).seek(startTime / 1000);
    }
  }

  render() {
    const settings = {
      infinite: this.props.playList.length > 5 ? true : false,
      slidesToShow: 6,
      slidesToScroll: this.props.playList.length > 5 ? 1 : this.props.playList.length,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    };
    return (
      <Slider ref={(slider) => (this.slider = slider)} {...settings}>
        {this.props.playList
          && this.props.playList.map((element, idx) => (
            <div
              key={element._id}
              id={element._id}
              data-streamtime={element.startTime}
              data-presentationid={element.presentationId}
              onClick={this.updateVideoPayerTime}
              className={`${
                this.props.activeThumbanilSlideID === element._id
                  ? 'thumbanil-slider-active'
                  : ''
              }`}
            >
              <img alt={element.slideThumb} src={element.slideThumb} />
            </div>
          ))}
      </Slider>
    );
  }
}
export default PlayListThumbanilSlider;
