import React, { useContext } from "react"
import Tabs from "react-responsive-tabs"
import Presentation from "./components/presentation"
import { PLAYER_EVENT_PLAY, PLAYER_EVENT_PAUSE } from "../../utils/constants"
import { onDemandPlayerContext } from "../../context/onDemandPlayer"
import { playerEvent } from "../../utils/analytics"
const Resources = props => {
  const [onDemandPlayerState, setOnDemandPlayerState] = useContext(
    onDemandPlayerContext
  )

  function getTabs(days, selectedPresentation) {
    return days
      .map((day, index) => {
        return {
          date: day.display_title,
          key: index,
          details: (
            <Presentation
              values={day.presentations}
              selectedPresentation={selectedPresentation}
            />
          ),
        }
      })
      .map((event, index) => ({
        title: event.date,
        getContent: () => event.details,
        key: index,
        tabClassName: "tab",
        panelClassName: "panel",
      }))
  }
  const onTabChange = () => {
    if (
      onDemandPlayerState &&
      onDemandPlayerState.eventType === PLAYER_EVENT_PLAY
    ) {
      playerEvent({
        eventType: PLAYER_EVENT_PAUSE,
        presentationId: onDemandPlayerState.presentationId,
        status: onDemandPlayerState.status,
      })
    }
  }

  return (
    <Tabs
      onChange={onTabChange}
      items={getTabs(props.days, props.selectedPresentation)}
      selectedTabKey={props.activeTabIndex}
    />
  )
}

export default Resources
