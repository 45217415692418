import PropTypes from "prop-types"
import React, { useContext } from "react"
import { PLAYER_EVENT_PLAY,PLAYER_EVENT_PAUSE } from "../../../utils/constants"
import { onDemandPlayerContext } from "../../../context/onDemandPlayer"
import { playerEvent } from "../../../utils/analytics"
const AccordionSection = ({ isOpen, label, onClick, children }) => {
  const [onDemandPlayerState, setOnDemandPlayerState] = useContext(
    onDemandPlayerContext
  )
  const handleClick = (event) => {
    event.preventDefault()
    if (
      onDemandPlayerState &&
      onDemandPlayerState.eventType === PLAYER_EVENT_PLAY
    ) {
      playerEvent({
        eventType: PLAYER_EVENT_PAUSE,
        presentationId: onDemandPlayerState.presentationId,
        status: onDemandPlayerState.status,
      })
    }
    onClick(label)
  }

  return (
    <div className={isOpen ? "accordion-item is-open" : "accordion-item"}>
      <div onClick={handleClick} className="accordion-label">
        {label}
      </div>
      {isOpen && <div className="accordion-details">{children}</div>}
    </div>
  )
}

AccordionSection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default AccordionSection
