import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExpand } from "@fortawesome/free-solid-svg-icons"

const Slides = props => {
  const currentSlideNumber = (slides, activeSlideID) => {
    let slideNumber = slides.findIndex(slide => {
      return slide._id === activeSlideID
    })

    return (slideNumber = slideNumber >= 0 ? slideNumber + 1 : 0)
  }
  return (
    <>
      <div
        className={`slide-show ${
          props.slideshowFullscreen ? "slide-show--fullscreen" : ""
        }`}
      >
        {/* <div className={`slide-show`}> */}
        <ul className={"pl-0 mb-0"} id="ulElement">
          {props.slides.map((element, idx) => (
            <li
              key={element._id}
              id={element._id}
              className={`slide-item ${
                props.activeSlideID === element._id ? "active" : ""
              }`}
            >
              <img alt={element.path} src={element.path}></img>
            </li>
          ))}
        </ul>
        <div className={"slide-bar d-xs-flex xs-center xs-middle"}>
          <div className={"slide-counter color-monochrome-2 d-xs-none"}>
            Slide{" "}
            {props.slides.length &&
              currentSlideNumber(props.slides, props.activeSlideID)}{" "}
            of {props.slides.length}
          </div>
          <button
            className={"button-icon icon-size-16"}
            onClick={props.onClick}
          >
            <FontAwesomeIcon
              icon={faExpand}
              className={"svg color-monochrome-2"}
            />
          </button>
        </div>
      </div>
    </>
  )
}

export default Slides
