import React, { Component } from "react"
import PropTypes from "prop-types"

import AccordionSection from "./AccordianSection"

class Accordion extends Component {
  static propTypes = {
    allowMultipleOpen: PropTypes.bool,
    children: PropTypes.instanceOf(Object).isRequired,
  }

  static defaultProps = {
    allowMultipleOpen: false,
  }

  constructor(props) {
    super(props)

    const openSections = {}

    this.props.children.forEach(child => {
      if (child.props.isOpen) {
        openSections[child.props.label] = true
      }
    })

    this.state = { openSections }
  }

  onClick = label => {
    const {
      props: { allowMultipleOpen },
      state: { openSections },
    } = this

    const isOpen = !!openSections[label]

    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [label]: !isOpen,
        },
      })
    } else {
      this.setState({
        openSections: {
          [label]: !isOpen,
        },
      })
    }
  }

  render() {
    const {
      onClick,
      props: { children },
      state: { openSections },
    } = this

    return (
      <div className="pt-xs-10 pl-xs-10 pr-xs-10 pb-xs-10 mb-xs-10 accordion-wrapper">
        {children.map((child, index) => (
          <AccordionSection
            key={index}
            isOpen={!!openSections[child.props.label]}
            label={child.props.label}
            onClick={onClick}
          >
            {child.props.children}
          </AccordionSection>
        ))}
      </div>
    )
  }
}

export default Accordion
