import React from 'react';
import PropTypes from 'prop-types';
import Accordian from './Accordian';
import OndemandStreaming from './OndemandStreaming';

const Presentation = (props) => {
  const { values, selectedPresentation } = props;
  //Checking if user clicked on "Watch on demand" link
  let selectedPresExists = values.find(v => v.presentation_id==selectedPresentation);

  return (

    <Accordian>
      {values.map((accordian, index) => {
        const isOpen = selectedPresExists ? accordian.presentation_id == selectedPresentation : index === 0;

        return (

          <div
            key={accordian.presentation_id}
            label={accordian.presentation_title}
            isOpen={isOpen}
          >
            <OndemandStreaming accordian={accordian} />
          </div>
        );
      })}
    </Accordian>
  );
};
Presentation.propTypes = {
  values: PropTypes.arrayOf.isRequired,
};
export default Presentation;
